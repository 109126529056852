<template>
    <div>
      <v-text-field
        v-model="title"
        label="Заголовок блока"
        @input="onTitleInput"
      ></v-text-field>
      <v-expansion-panels class="mb-4">
        <v-expansion-panel
          v-for="item in faq"
          :key="item.id"
        >
          <v-expansion-panel-header>
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
                v-model="item.question"
                label="Вопрос"
              ></v-text-field>
            <quill :options="quillExtended" v-model="item.answer" label="Ответ" />
            <v-btn
              color="error"
              @click.prevent="onRemove(item.id)"
            >
              Удалить
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn
        color="success"
        @click.prevent="onAdd"
      >
        Добавить
      </v-btn>
    </div>
</template>

<script>
import { QUILL_EXTENDED } from '@/components/elements/quilljs/configs'
import { v4 as uuid } from 'uuid'

export default {
  data() {
    return {
      quillExtended: QUILL_EXTENDED,
      title: this.faqTitle,
      faq: this.faqItems
    }
  },
  props: {
    faqItems: Array,
    faqTitle: String
    },
  methods: {
    onAdd() {
      this.faq.push({id: uuid(), question: 'Текст вопроса', answer: ''})
    },
    onRemove(id) {
      this.faq = this.faq.filter(item => item.id !== id)
      this.$emit('remove-faq', this.faq)
    },
    onTitleInput() {
      this.$emit('change-title', this.title)
    }
  },
}
</script>
